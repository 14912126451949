import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Validators, FormBuilder, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CampaignService } from 'src/app/services/campaign.service';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

declare const $: any;
declare const moment: any;

@Component({
  selector: 'app-filter-general-form',
  templateUrl: './filter-general-form.component.html',
  styleUrls: ['./filter-general-form.component.css']
})
export class FilterGeneralFormComponent implements OnInit {

  customerId = new FormControl(null)
  dateRange = new FormControl(null)
  rangeDate = this.formBuilder.group({
    from: [null, [Validators.required]],
    to: [null]
  })
  reportId: number = 0
  allPlatforms: boolean = false
  allNotes: boolean = false
  allTiers: boolean = false
  isVal: boolean = false
  isDet: boolean = false

  user: any;

  @Output() submittedForm = new EventEmitter<any>();
  @Output() typeReport = new EventEmitter<any>();

  view: number = 1;

  customers: any[] = [];
  platforms: any[] = [];
  notes: any[] = [
    { id: 1, descripcion: "Nota", name: 'n1', check: false, disabled: true },
    { id: 2, descripcion: "Entrevista", name: 'n2', check: false, disabled: true },
    { id: 3, descripcion: "Reseña", name: 'n3', check: false, disabled: true },
    { id: 4, descripcion: "Opinión", name: 'n4', check: false, disabled: true }
  ];

  tiers: any[] = [
    { id: 1, descripcion: "Tier 1", name: 't1', check: false, disabled: true },
    { id: 2, descripcion: "Tier 2", name: 't2', check: false, disabled: true },
    { id: 3, descripcion: "Tier 3", name: 't3', check: false, disabled: true }
  ];

  reportes: any[] = [
    { id: 1, name: "Reporte de Campañas", check: false },
    { id: 2, name: "Reporte de Voceros", check: false },
    { id: 3, name: "Reporte de Plan de Medios", check: false },
    { id: 4, name: "Reporte de Plataformas", check: false },
    { id: 5, name: "Reporte de Tipo de Nota", check: false }
  ];

  ylabel: any = null
  xlabel: any = null

  spokesmen: any[] = [];
  campaignSelect: any[] = [];
  plans: any[] = [];

  results: any[] = [];

  customer: any = null;
  reportSelect: string = '';

  loadF = false
  constructor(
    private campaignService: CampaignService,
    public userService: UserService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.user = this.userService.user.value;
    this.select2()

    this.campaignService.getPlatforms().subscribe(response => {
      this.platforms = response.map(pl => {
        return {
          ...pl,
          name: 'p' + pl.id,
          disabled: true,
          check: false
        }
      })
    });

    if (this.user.cliente_id > 0) {
      this.customerChange(this.user.cliente_id);
      this.view = 2
    }

    this.campaignService.getCustomers().subscribe(response => {
      if (this.user.cliente_id > 0) {
        this.customer = response.find(c => c.id === this.user.cliente_id).nombreComercial;
      }
      this.customers = response;
    });


  }


  customerChange(value) {
    this.customerId.setValue(value)
    this.customer = value ? this.customers.find(ct => ct.id == value) : null
    this.campaignService.getSpokesmen(value).subscribe(response => {
      this.spokesmen = response;
    });
    this.campaignService.getCampaignSelect(value).subscribe(response => {
      this.campaignSelect = response;
    });
    this.campaignService.getPlans(value).subscribe(response => {
      this.plans = response;
    });
  }

  select2() {
    $(document).ready(() => {
      $('#customer').select2({
        width: '100%',
        placeholder: 'Seleccione',
        allowClear: true
      });

      $('#customer').on('select2:select', (e) => {
        let id = Number(e.params.data.id);
        this.customerChange(id);
      });

      $('#customer').on('select2:clear', (e) => {
        this.customerChange(null);
      });

      $('input[name="daterange"]').daterangepicker({
        locale: {
          format: 'DD/MM/YYYY',
          "daysOfWeek": ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
          "monthNames": [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
          ]
        },
        autoApply: true,
        autoUpdateInput: false,
        linkedCalendars: false,
        showDropdowns: true
      });

      $('input[name="daterange"]').on('apply.daterangepicker', (e, picker) => {
        let startDate = picker.startDate.format('YYYY-MM-DD');
        let endDate = picker.endDate.format('YYYY-MM-DD');
        $('input[name="daterange"]').val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
        this.dateRange.setValue(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'))
        this.rangeDate.get('from').setValue(startDate);
        this.rangeDate.get('to').setValue(endDate);
      });

    });
  }

  backView() {
    this.view--
    if (this.view == 1 || this.view == 4) {
      this.select2()
    }
  }

  changeView() {
    this.view++

    if (this.view == 4) {
      this.select2()
    }
  }

  disableNextButon(): boolean {
    switch (this.view) {
      case 1:
        return !!this.customerId.value
      case 2:
        return !!this.reportId
      case 3:
        const not = this.notes.filter(n => n.check).length > 0 && this.allNotes
        const plats = this.platforms.filter(p => p.check).length > 0 && this.allPlatforms
        const tier = this.tiers.filter(p => p.check).length > 0 && this.allTiers
        return this.reportId < 4 ? not || plats || tier : true
      case 4:
        return !!this.dateRange.value
      default:
        break;
    }
  }

  reportChange(report) {
    this.reportId = report.check ? report.id : null
    this.reportSelect = report.name
    this.reportes.forEach(r => {
      if (r.id != report.id && r.check) {
        r.check = false
      }
    })
  }

  allPlatformsChange() {
    if (this.allPlatforms) {
      this.platforms.forEach(p => {
        p.disabled = false
        p.check = true
      })
      if (this.allNotes) {
        this.allNotes = false
        this.notes.forEach(n => {
          n.check = false
          n.disabled = true
        })
      }
      if (this.allTiers) {
        this.allTiers = false
        this.tiers.forEach(n => {
          n.check = false
          n.disabled = true
        })
      }
    } else {
      this.platforms.forEach(p => {
        p.check = false
        p.disabled = true
      })
    }
  }

  allNotesChange() {
    if (this.allNotes) {
      this.notes.forEach(n => {
        n.disabled = false
        n.check = true
      })
      if (this.allPlatforms) {
        this.allPlatforms = false
        this.platforms.forEach(p => {
          p.check = false
          p.disabled = true
        })
      }
      if (this.allTiers) {
        this.allTiers = false
        this.tiers.forEach(n => {
          n.check = false
          n.disabled = true
        })
      }
    } else {
      this.notes.forEach(n => {
        n.check = false
        n.disabled = true
      })
    }
  }

  allTiersChange() {
    if (this.allTiers) {
      this.tiers.forEach(n => {
        n.disabled = false
        n.check = true
      })
      if (this.allPlatforms) {
        this.allPlatforms = false
        this.platforms.forEach(p => {
          p.check = false
          p.disabled = true
        })
      }
      if (this.allNotes) {
        this.allNotes = false
        this.notes.forEach(n => {
          n.check = false
          n.disabled = true
        })
      }
    } else {
      this.tiers.forEach(n => {
        n.check = false
        n.disabled = true
      })
    }
  }

  onSubmit() {
    this.view = 6

    const x = this.allPlatforms ? 1 : this.allNotes ? 2 : this.allTiers ? 3 : 0
    const y = this.reportId

    let campaignSend = this.campaignSelect.map(c => c.id)
    let saveData = {
      idCliente: this.customerId.value,
      campaigns: y == 1 || x == 0 ? campaignSend : [],
      voceros: y == 2 ? this.spokesmen.map(s => s.id) : [],
      planmedios: y == 3 ? this.plans.map(s => s.id) : [],
      plataformas: y == 4 ? this.platforms.map(p => p.id) : this.platforms.filter(p => p.check).map(p => p.id),
      tipoNotas: y == 5 ? this.notes.map(p => p.id) : this.notes.filter(p => p.check).map(p => p.id),
      tipoTiers: y == 6 ? this.tiers.map(p => p.id) : this.tiers.filter(p => p.check).map(p => p.id),
      fechaInicio: this.rangeDate.get('from').value,
      fechaFin: this.rangeDate.get('to').value,
      isVal: this.isVal,
      isDet: this.isDet
    }

    let info = {
      report: this.reportId,
      customer: this.customer,
      name: this.reportSelect,
      range: this.dateRange.value
    }

    switch (x) {
      case 1:
        info.name = `${this.reportSelect} por plataformas`
        break;
      case 2:
        info.name = `${this.reportSelect} por tipo de notas`
        break;
      case 3:
        info.name = `${this.reportSelect} por tiers`
        break;
      default:
        info.name = this.reportSelect
        break;
    }

    this.typeReport.emit(info)

    this.campaignService.getAll(saveData).subscribe((response: any) => {
      response['customer'] = this.customers.find(cl => cl.id == this.customerId.value)
      response.DPRPs = response.DPRPs.map(el => {
        el.note = this.notes.find(nt => nt.id === el.tipoNota)?.descripcion
        el.tierName = this.tiers.find(nt => nt.id === el.tipoTier)?.descripcion
        el.valorizar = Number(el.valorizado.toFixed(2))
        return el
      })
      response.params.xlabel = x
      response.params.ylabel = y
      response.params.platforms = this.platforms.filter(pl => saveData.plataformas.includes(pl.id))
      response.valorizadoTotal = Number(response.valorizadoTotal.toFixed(2))

      this.submittedForm.emit(response);
    }, (error) => {

    });
  }

}
