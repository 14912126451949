import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from '../models/user.model';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private url = environment.url;
  public user: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public token: BehaviorSubject<string> = new BehaviorSubject<string>(null);


  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    this.load();
  }

  load() {
    this.token.next(localStorage.getItem('token'));
    this.user.next(JSON.parse(localStorage.getItem('user')));
  }

  login(user: User) {
    return this.http.post(`${this.url}/api/auth/loginClipping`, user).pipe(
      tap((response: any) => {
        this.token.next(response.token);
        localStorage.setItem('token', response.token);
      })
    );
  }

  loginUser(user) {
    this.user.next(user.user);
    localStorage.setItem('user', JSON.stringify(user.user));
    /*
        return this.http.get(`/authenticate/user`).pipe(
          tap((response: any) => {
            this.user.next(response.user);
            localStorage.setItem('user', JSON.stringify(response.user));
          })
        );*/
  }

  logout() {
    localStorage.clear();
    this.user.next(null);
    this.token.next(null);
    this.router.navigate(['/login']);
  }

  getUser() {
    return this.http.get(`/users/${this.user.value.id}`).pipe(
      tap((response: any) => {
        this.user.next(response.data);
        localStorage.setItem('user', JSON.stringify(response.data));
      })
    );
  }

  update(user: any) {
    return this.http.post(`/users`, user).pipe(
      tap((response: any) => {
        this.user.next(response.id);
        localStorage.setItem('user', JSON.stringify(this.user));
      })
    );
  }

  recoverPasswordOne(email) {
    return this.http.post(`${this.url}/api/sendTemporaryPassword`, email);
  }

  recoverPasswordTwo(data) {
    return this.http.post(`${this.url}/api/resetPassword`, data);
  }

  passwordChange(newPassword) {
    return this.http.post(`${this.url}/api/clientes/new/changePassword`, { id: this.user.value.id, newPassword }).pipe(
      tap((response: any) => {
        this.user.next(response.usuario);
        localStorage.setItem('user', JSON.stringify(response.usuario));
      })
    );
  }

  getInfoBusiness() {
    return this.http.get(`/informacionEmpresa`)
  }
}
