<nav class="navbar navbar-expand-lg navbar-light fixed-top bg-white px-5" style="height: 72px;">
    <a class="navbar-brand" routerLink="/page/home">
        <img src="assets/img/Logotipo-07.png" height="30" alt="">
    </a>


    <ul class="ml-auto nav-responsive" >
        <a class="nav-link" routerLink="**" style="padding: 0;">Inicio</a>
        <li>
            <div style="border-right: 1px solid #C7C7C7; height: 30px; margin: 0px 19.5px;"></div>
        </li>
        <li class="nav-item dropdown" *ngIf="user;else noLogin">
            <a class="nav-link dropdown-toggle d-flex align-items-center" id="navbarDropdown" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="padding: 0;">
                <img [src]="user | image:'user' | async" height="40" class="rounded-circle" style="cursor: pointer;">
                <p class="m-0 pl-2 xs-hide" style="cursor: pointer;">{{user.name}}</p>
            </a>
            <div class="dropdown-menu dropdown-menu-right mt-2" aria-labelledby="navbarDropdown">
                <p class="dropdown-item" (click)="profile()">Mi perfil</p>
                <button class="dropdown-item" (click)="logout()">
                    <i class="fa fa-sign-out" aria-hidden="true"></i>
                    Cerrar sesión
                </button>
            </div>
        </li>
        <ng-template #noLogin>
            <li class="nav-item xs-hide">
                <a class="nav-link" routerLink="/login">Iniciar sesión</a>
            </li>
        </ng-template>

    </ul>
</nav>
