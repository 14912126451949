import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dolar'
})
export class DolarPipe implements PipeTransform {

  transform(value: number, currency?): unknown {
    let symbol = currency == 'sol' ? 'S/.' : 'USD.'
    let number = value.toLocaleString("fr", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).replace(',', '.')
    if (currency) {
      return `${symbol} ${number}`
    } else {
      return number
    }
  }

}
