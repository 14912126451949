import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { NgForm, NgModel } from '@angular/forms';
import Swal from 'sweetalert2';

declare const $: any;

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css'],
})
export class RecoverPasswordComponent implements OnInit {

  @ViewChild('closebutton') closebutton: ElementRef;
  email: string = '';
  formSubmitAttempt: boolean = false;
  code: boolean = false;
  btn: boolean = false;
  btnTwo: boolean = false;
  incorrectData: boolean = false;
  passwordNew: any = {
    codeResetPassword: '',
    newPassword: '',
    passwordTwo: ''
  }

  constructor(
    private userService: UserService
  ) { }

  ngOnInit(): void {
  }

  recoverPasswordOne(form: NgForm) {
    this.formSubmitAttempt = true;
    this.btn = true;
    if (form.invalid) {
      this.btn = false;
      return;
    }
    this.userService.recoverPasswordOne(form.value).subscribe(response => {
      this.formSubmitAttempt = false;
      this.code = true;
      this.passwordNew.codeResetPassword = '';
      this.passwordNew.newPassword = '';
      this.passwordNew.passwordTwo = '';
    }, err => {
      this.btn = false;
      this.formSubmitAttempt = false;
      if (err.status === 400) {
        $('.alertPasswordForm').show();
      } else {
        Swal.fire({
          text: 'Se ha producido un error',
          imageUrl: 'assets/img/error.png',
          customClass: {
            title: 'my-swal-title-class',
            content: 'my-swal-content-class',
            confirmButton: 'my-swal-confirm-button-class',
          }
        });
      }
    });
  }

  recoverPasswordTwo(form: NgForm) {
    this.formSubmitAttempt = true;
    this.btnTwo = true;
    if (form.invalid) {
      this.btnTwo = false;
      return;
    }
    if (this.passwordNew.newPassword !== this.passwordNew.passwordTwo) {
      $('.alertpasswordFormTwo-danger').show();
      this.btnTwo = false;
      return;
    }
    this.passwordNew.user = this.email;
    this.userService.recoverPasswordTwo(this.passwordNew).subscribe(response => {
      this.formSubmitAttempt = false;
      $('#exampleModal').modal('hide')
      Swal.fire({
        text: 'Su contraseña ha sido actualizada satisfactoriamente',
        imageUrl: 'assets/img/check-in.png',
        customClass: {
          title: 'my-swal-title-class',
          content: 'my-swal-content-class',
          confirmButton: 'my-swal-confirm-button-class',
        }
      });
    }, err => {
      this.btnTwo = false;
      if (err.status === 400) {
        $('.alertpasswordFormTwo-dangerTwo').show();
      } else {
        Swal.fire({
          text: 'Se ha producido un error',
          imageUrl: 'assets/img/error.png',
          customClass: {
            title: 'my-swal-title-class',
            content: 'my-swal-content-class',
            confirmButton: 'my-swal-confirm-button-class',
          }
        });
      }
    });
  }

  isFieldValid(field: NgModel): boolean {
    return field.invalid && this.formSubmitAttempt;
  }

  closeAlert(data) {
    if (data === 1) {
      $('.alertPasswordForm').hide();
    }
    if (data === 2) {
      $('.alertpasswordFormTwo-success').hide();
    }
    if (data === 3) {
      $('.alertpasswordFormTwo-danger').hide();
    }
    if (data === 4) {
      $('.alertpasswordFormTwo-dangerTwo').hide();
    }
  }

  clickCancel() {
    this.closebutton.nativeElement.click();
  }

  cancel() {
    this.formSubmitAttempt = false;
    this.code = false;
    this.btn = false;
    this.btnTwo = false;
    this.email = '';
  }

}
