import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { DatePipe } from '@angular/common';
import { logoBase64 } from './logo';

// declare const ExcelJS: any;
// declare const FileSaver: any;

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor(private datePipe: DatePipe) { }

  tipoReportes = {
    1: 'ReportePorPlataformas',
    2: 'ReportePorCampanasAndPlataformas',
    3: 'ReportePorVocerosAndPlataformas',
    4: 'ReportePorPlanMediosAndPlataformas',
    5: 'ReportePorTipoNotasAndPlataformas',
    6: 'ReportePorTipoTiersAndPlataformas',
    7: 'ReportePorTipoNotas',
    8: 'ReportePorCampanasAndTipoNotas',
    9: 'ReportePorVocerosAndTipoNotas',
    10: 'ReportePorPlanMediosAndTipoNotas',
    11: 'ReportePorPlataformasAndTipoNotas',
    12: 'ReportePorTipoTiersAndTipoNotas',
    13: 'ReportePorTipoTiers',
    14: 'ReportePorCampanasAndTipoTiers',
    15: 'ReportePorVocerosAndTipoTiers',
    16: 'ReportePorPlanMediosAndTipoTiers',
    17: 'ReportePorPlataformasAndTipoTiers',
    18: 'ReportePorTipoNotasAndTipoTiers',
  };

  exportToExcel(data, type: number) {

    let name = this.tipoReportes[type] || 'Reporte';
    
    let fileName = `${name}_${this.datePipe.transform(data.params.fechaInicio, 'dd-MM-yyyy')}-${this.datePipe.transform(data.params.fechaFin, 'dd-MM-yyyy')}`;

    let header = ['ID', 'TITULO', 'CONTACTO', 'MEDIO', 'PROGRAMA', 'VOCERO', 'PLATAFORMA','TIPO DE NOTAS', 'PUBLICACIÓN', 'VALORIZACIÓN (S/.)'];

    if (type === 3) {
      header = ['ID', 'VOCERO', 'CAMPAÑA', 'PLAN DE MEDIO', 'CONTACTO', 'MEDIO', 'PROGRAMA', 'PLATAFORMA','TIPO DE NOTAS', 'PUBLICACIÓN', 'VALORIZACIÓN (S/.)'];
    }

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Hoja1');

    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 15;
    worksheet.getColumn(8).width = 15;
    worksheet.getColumn(9).width = 15;
    worksheet.getColumn(10).width = 25;
    worksheet.addRow([], 'n');
    worksheet.addRow([], 'n');
    worksheet.addRow([], 'n');

    const logo = workbook.addImage({
      base64: logoBase64,
      extension: 'png',
    });

    worksheet.addImage(logo, 'A1:B3');
    worksheet.mergeCells('A1:C3');


    worksheet.autoFilter = 'A4:J4';

    if (type === 3) {
      worksheet.autoFilter = 'A4:K4';
      worksheet.getColumn(10).width = 25;
    }

    const headerRow = worksheet.addRow(header, 'n');

    headerRow.eachCell(cell => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF4F81BD' }
      };

      cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
    });

    data.DPRPs.forEach((d, index) => {
      let rowData = [d.idDetallePlanMedio || '', d.Campaign || '', d.Contacto || '', d.Medio || '', d.Programa || '', d.voceros || '', d.Plataforma || '', d.note || '', this.datePipe.transform(d.fechaPublicacion, 'dd-MM-yyy') || '', d.valorizar || 0]

      if (type === 3) {
        rowData = [d.idDetallePlanMedio || '', d.Vocero || '', d.Campaign || '', d.PlanMedio || '', d.Contacto || '', d.Medio || '', d.Programa || '', d.Plataforma || '', d.note || '', this.datePipe.transform(d.fechaPublicacion, 'dd-MM-yyy') || '', d.valorizar || 0]
      }

      const row = worksheet.addRow(rowData, 'n');

      row.getCell(1).alignment = { horizontal: 'left' };

      row.border = {
        top: { style: 'thin', color: { argb: 'FFDCE6F1' } },
        left: { style: 'thin', color: { argb: 'FFDCE6F1' } },
        bottom: { style: 'thin', color: { argb: 'FFDCE6F1' } },
        right: { style: 'thin', color: { argb: 'FFDCE6F1' } }
      };

      if (index % 2 === 0) {
        row.eachCell(cell => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFDCE6F1' }
          };
        });
      }
    });

    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fileName + '.xlsx');
    });
  }
}