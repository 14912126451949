import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    /*const token = this.userService.token.value;
    const isApiUrl = req.url.startsWith(environment.url);

    if (token && isApiUrl) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }*/

    const isLoggedIn = this.userService.user.value && this.userService.token.value;
    const isApiUrl = request.url.includes('api') || request.url.includes('https:');
    if (isLoggedIn && !isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.userService.token.value}`,

        },
        //url: `${environment.url}/${this.userService.user.value.tenant_id}${request.url}`
        url: `${environment.url}/api${request.url}`
      });
    }

    return next.handle(request).pipe(
      catchError(error => {
        if (error.status === 500 && this.expiredToken()) {
          this.userService.logout();
          this.router.navigate(['/login']);
          return throwError('El token ha expirado');
        } else {
          return throwError(error);
        }
      })
    );

  }

  expiredToken(): boolean {
    let token = this.userService.token.value;
    if (!token) {
      return true;
    }
    let payload = JSON.parse(atob(token.split('.')[1]));
    let exp = payload.exp * 1000;
    let date = new Date().getTime();
    if (date > exp) {
      return true;
    } else {
      return false;
    }
  }

}
