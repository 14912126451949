import { Component, HostListener, OnInit } from '@angular/core';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'blog';

  constructor(
    private userService: UserService
  ) { }

  @HostListener('window:storage', ['$event'])
  handleStorage(event: StorageEvent) {
    if (event.storageArea.length === 0) {
      this.userService.token.next(null);
    }
  }

  ngOnInit() {
    // window.onstorage = () => {
    //   console.log('ngOnInit');
    // };
  }
}
