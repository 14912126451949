import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ImageService } from '../services/image.service';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {
  private url: string = environment.url;

  constructor(private imageService: ImageService) { }

  async transform(data: any, type?: string): Promise<string> {
    try {
      let url = '';
      switch (type) {
        case 'user':
          url = `/users/data/displayImage/${data.id}`
          break;
        case 'customer':
          url = `/clientes/data/displayImage/${data.id}`
          break;
        case 'resultado':
          url = `/resultadoPlataformas/data/displayImage/${data}`
          break;
        case 'resultadoImage':
          url = `/resultadoPlataformas/data/displayImageResultado/${data}`
          break;
        case 'logo':
          url = `/informacionEmpresa/displayLogo/${data}`
          break;
      }
      const imageBlob = await this.imageService.get(url);
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(imageBlob);
      });
    } catch (error) {
      if (type == 'logo') {
        return 'assets/img/Logotipo-07.png';
      } else {
        return 'assets/img/default.png';
      }

    }
  }

}
