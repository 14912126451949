import { Component, OnInit, Input } from '@angular/core';
import {Router} from '@angular/router';
import {CampaignService} from 'src/app/services/campaign.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {

  @Input() results: any;
  @Input() datas: any;

  search: string = '';
  dataSlice: any[] = [];
  data: any[] = [];

  xlabel: number = 1
  ylabel: number = 1

  constructor(
    private router: Router,
    private campaignService: CampaignService
  ) { }

  ngOnInit() {
    this.data = this.results.DPRPs;
    this.xlabel = this.results.params.xlabel
    this.ylabel = this.results.params.ylabel
    this.dataSlice = this.data.slice(0, 15);
    console.log(this.datas)
  }

  onModalScrollDown() {
    if (this.dataSlice.length < this.data.length) {
      let lengthArray = this.dataSlice.length;
      let lengthArrayTwo = this.data.length;
      for (let i = lengthArray; i < lengthArray + 10 && i < lengthArrayTwo; i++) {
        this.dataSlice.push(this.data[i])
      }
    }
  }

  	campana(idEncriptado){
      	this.campaignService.getParam(this.datas)
      	const url = this.router.createUrlTree(['/page/campaign', idEncriptado])
    	window.open(url.toString(), '_blank')
  	}

  onSearch() {
    if (this.search) {
      if (this.results.params.campaigns.length > 0) {
        this.data = this.results.DPRPs.filter(r => r.PlanMedio.toLowerCase().indexOf(this.search.toLowerCase()) > -1);
      } else {
        this.data = this.results.DPRPs.filter(r => r.Vocero.toLowerCase().indexOf(this.search.toLowerCase()) > -1);
      }
      this.dataSlice = this.data.slice(0, 15);
    } else {
      this.data = this.results.DPRPs;
      this.dataSlice = this.data.slice(0, 15);
    }
  }

}
