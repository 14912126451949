<div style="align-items: center;" class="result row">
    <div class="col-md-6" style="height: fit-content;">
        
        <div *ngIf="xlabel==1" style="margin: 15px 0;">
            <span class="badge" [ngClass]="{'badge-primary': item.idPlataforma === 5, 
            'badge-success': item.idPlataforma === 3, 
            'badge-warning': item.idPlataforma === 9,
            'badge-info': item.idPlataforma === 1,
            'badge-danger': item.idPlataforma === 2}">
                {{item.Plataforma}}
            </span>
        </div>
        <div *ngIf="xlabel==2" style="margin: 15px 0;">
            <span class="badge" [ngClass]="{'badge-success': item.tipoNota === 2, 
            'badge-warning': item.tipoNota === 3,
            'badge-danger': item.tipoNota === 1,
            'badge-primary': item.tipoNota === 4}">
                {{item.note}}
            </span>
        </div>
        <div *ngIf="xlabel==3" style="margin: 15px 0;">
            <span class="badge" [ngClass]="{'badge-success': item.tipoTier === 2, 
            'badge-warning': item.tipoTier === 3,
            'badge-danger': item.tipoTier === 1}">
                TIER {{item.tipoTier}}
            </span>
        </div>
        <ul style="list-style: none; padding: 0;">
            <li *ngIf="ylabel!=1"><strong>CAMPAÑA:</strong> {{item.Campaign}}</li>
            <li><strong>MEDIO:</strong> {{item.Medio}}</li>
            <li *ngIf="item.Programa"><strong>PROGRAMA:</strong>
                {{item.Programa}}</li>
            <li *ngIf="item.voceros"><strong>VOCERO(S):</strong>
                {{item.voceros}}</li>
            <li><strong>FECHA:</strong>
                {{item.fechaPublicacion | date:'dd-MM-yyyy'}}
            </li>
            <li *ngIf="item.url">
                <strong>LINK: </strong>
                <a href="javascript:void(0)" (click)="goTo(item.url)">{{item.url}}</a>
            </li>
            <li *ngIf="ylabel!=4">
                <strong>PLATAFORMA: </strong>
                {{item.Plataforma}}
            </li>
            <li *ngIf="item.idPlataforma===9">
                <strong>RED:</strong>
                {{item.Clasificacion}}
            </li>
            <li *ngIf="item.idPlataforma===5">
                <strong>SECCIÓN:</strong>
                {{item.Detalle}}
            </li>
            <li *ngIf="item.idPlataforma===5">
                <strong>CM<sup>2</sup>:</strong>
                {{item.cm2 ? [item.cm2 | number:'.2'] : '0.00'}} cm<sup>2</sup>
            </li>
            <li *ngIf="item.idPlataforma===2 || item.idPlataforma===3">
                <strong>DURACIÓN:</strong>
                {{item.segundos ? item.segundos : '00:00'}} segundos
            </li>
            <li *ngIf="val">
                <strong>VALOR:</strong>
                {{item.valorizar | dolar:currency}}
            </li>
            <li *ngIf="det">
                <strong>ALCANCE:</strong>
                {{item.Alcance | dolar}}
            </li>
        </ul>
    </div>
    <div class="col-md-6 d-flex align-items-center justify-content-center">
        <a target="_blank" [routerLink]="['/page/campaign', item.idEncriptado]">
            <img [src]="item.idResultadoPlataforma  | image:'resultado' | async" offset="100" alt="result"
                class="img-result">

        </a>

    </div>
</div>