import { Component, OnInit, Input, Output, EventEmitter, Injectable } from '@angular/core';


@Component({
  selector: 'app-range-selection',
  templateUrl: './range-selection.component.html',
  styleUrls: ['./range-selection.component.css'],
})
export class RangeSelectionComponent implements OnInit {

  @Output() selectedFrom = new EventEmitter<any>();
  @Output() selectedTo = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() { }
}
