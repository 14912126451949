import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { CampaignService } from 'src/app/services/campaign.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  user: any;

  constructor(
    public userService: UserService,
    public campaignService: CampaignService,
    private router: Router
  ) { }

  ngOnInit() {
    this.userService.user.subscribe(user => {
      this.user = user;
    });
  }

  profile() {
      this.router.navigate(['page/profile'])
  }

  logout() {
    this.userService.logout();
    this.campaignService.cleanData();
  }

}
