import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { CardComponent } from './card/card.component';
import { PaginationComponent } from './pagination/pagination.component';
import { RouterModule } from '@angular/router';
import { PipeModule } from '../pipes/pipe.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalComponent } from './modal/modal.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FilterFormComponent } from './filter-form/filter-form.component';
import { RangeSelectionComponent } from './range-selection/range-selection.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { ResultDivComponent } from './result-div/result-div.component';
import { FilterGeneralFormComponent } from './filter-general-form/filter-general-form.component';
import { MultipleSelectComponent } from './multiple-select/multiple-select.component';



@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    CardComponent,
    PaginationComponent,
    ModalComponent,
    FilterFormComponent,
    RangeSelectionComponent,
    RecoverPasswordComponent,
    ResultDivComponent,
    FilterGeneralFormComponent,
    MultipleSelectComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    PipeModule,
    FormsModule,
    ScrollingModule,
    InfiniteScrollModule,
    ReactiveFormsModule
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    CardComponent,
    PaginationComponent,
    ModalComponent,
    FilterFormComponent,
    RecoverPasswordComponent,
    FilterGeneralFormComponent
  ]
})
export class SharedModule { }
