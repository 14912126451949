<div class="row">
    <div class="col">
        <p class="total-data">Total: {{data.length}} resultados </p>
    </div>
</div>
<div class="row">
    <div class="col">
        <div class="form-group">
            <input [(ngModel)]="search" (ngModelChange)="onSearch()" type="text" class="form-control" id=""
                placeholder="Buscar">
        </div>
    </div>
</div>
<div class="row row-infiniteScroll" infiniteScroll #scrollTwo [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
    [scrollWindow]="false" (scrolled)="onModalScrollDown()">
    <div class="col-md-4 p-1" *ngFor="let result of dataSlice">
        <div class="card">
            <div class="card-body">
                <div class="container-image d-flex justify-content-center align-items-center">
                    <a (click)="campana(result.idEncriptado)">
                        <img [src]="result.idResultadoPlataforma  | image:'resultado' | async" alt="...">
                    </a>
                </div>
                <hr style="margin: 15px -20px;">
                <h5 *ngIf="ylabel!=2" class="card-title">
                    <button type="button" class="btn btn-secondary btn-sm" (click)="campana(result.idEncriptado)">{{result.PlanMedio}}</button>
                </h5>
                <h5 *ngIf="ylabel==2" class="card-title"><a
                    (click)="campana(result.idEncriptado)">{{result.Vocero}}</a>
                </h5>
                <div *ngIf="xlabel==1" class="d-flex flex-wrap">
                    <p class="card-text my-0 mr-2"><small class="text-muted">Plataforma:</small></p>
                    <p class="card-text my-0 mr-2">
                        <span class="badge" [ngClass]="{'badge-primary': result.idPlataforma === 5, 
                        'badge-success': result.idPlataforma === 3, 
                        'badge-warning': result.idPlataforma === 9,
                        'badge-info': result.idPlataforma === 1,
                        'badge-danger': result.idPlataforma === 2}">{{result.Plataforma}}</span>
                    </p>

                </div>
                <div class="d-flex flex-wrap" *ngIf="xlabel==2">
                    <p class="card-text my-0 mr-2"><small class="text-muted">Tipo de Nota:</small></p>

                    <p class="card-text my-0 mr-2" *ngIf="result.tipoNota === 2"><span
                            class="badge badge-success">Nota</span></p>
                    <p class="card-text my-0 mr-2" *ngIf="result.tipoNota === 1"><span
                            class="badge badge-danger">Entrevista</span></p>
                    <p class="card-text my-0 mr-2" *ngIf="result.tipoNota === 3">
                        <span class="badge badge-warning">Reseña</span>
                    </p>
                    <p class="card-text my-0 mr-2" *ngIf="result.tipoNota === 4">
                        <span class="badge badge-primary">Opinión</span>
                    </p>
                </div>

                <div *ngIf="xlabel==3 && result.tipoTier" class="d-flex flex-wrap">
                    <p class="card-text my-0 mr-2"><small class="text-muted">Tier:</small></p>
                    <p class="card-text my-0 mr-2">
                        <span class="badge" [ngClass]="{'badge-success': result.tipoTier === 2, 
                        'badge-warning': result.tipoTier === 3,
                        'badge-danger': result.tipoTier === 1}">
                            TIER {{result.tipoTier}}
                        </span>
                    </p>
                </div>
                <div class="d-flex flex-wrap">
                    <p class="card-text my-0 mr-2">
                        <small class="text-muted">
                            Fecha Publicación: {{result.fechaPublicacion | date:'dd/MM/yyyy'}}
                        </small>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>