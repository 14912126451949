<div class="row col card">
    <div class="card-body">
        <h3> Filtrar <i class="fa fa-spinner fa-pulse" *ngIf="loadF"></i></h3>
		<hr>
		<form
			[formGroup]="filterForm"
			class=".was-validated"
      		(ngSubmit)="onSubmit()"
      		autocomplete="off">

            <div class="timeline timeline-one-side" style="margin-top: 15px;">
                <div class="timeline-block">
                    <span class="timeline-step">1</span>
                    <div class="timeline-content">
                    	<h3>Seleccionar cliente</h3>
                        <div class="content-form">
                            <div class="form-row">
                                <div class="form-group col-md-6" *ngIf="user">
                                    <label for="">Cliente <span style="color: #FF3333;"><b>*</b></span></label>
                                    <input *ngIf="user.cliente_id;else allCustomers" type="text"
                                        class="form-control" readonly [value]="customer">
                                    <ng-template #allCustomers>
                                        <select class="form-control" id="customer"
                                            [ngClass]="{'is-invalid': isFieldValid('customer')}"
                                            formControlName="customer">
                                            <option *ngFor="let c of customers" [value]="c.id">
                                                {{c.nombreComercial}}
                                            </option>
                                        </select>
                                    </ng-template>
                                    <div class="invalid-tooltip">
                                        Completa este campo.
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="">Desde - Hasta <span style="color: #FF3333;"><b>*</b></span></label>
                                    <input type="text" class="form-control" name="daterange"
                                        [ngClass]="{'is-invalid': isFieldValid('from')}" />
                                    <div class="invalid-tooltip">
                                        Completa este campo.
                                    </div>
                                </div>
                          </div>
                        </div>
                    </div>
                </div>

                <div class="timeline-block">
                            <span class="timeline-step">2</span>
                            <div class="timeline-content">
                                <div>
                                    <h3>Especificar Reporte</h3>
                                </div>
                                <div class="content-form">
                                    <div class="form-row col-lg-12" style="align-items: baseline;">
                                        <label> Reporte de </label>
                                        <div class="form-group col-md-4">
                                            <select class="form-control" id="ylabel"
                                                [ngClass]="{'is-invalid': isFieldValid('ylabel')}">
                                                <option></option>
                                                <option *ngFor="let c of filterArr(ylabel, 'xlabel')" [value]="c.type">
                                                    {{c.name}}
                                                </option>
                                            </select>
                                            <div class="invalid-tooltip">
                                                Completa este campo.
                                            </div>
                                        </div>
                                        <label> con </label>
                                        <div class="form-group col-md-4">
                                            <select class="form-control" id="xlabel"
                                                [ngClass]="{'is-invalid': isFieldValid('xlabel')}">
                                                <option></option>
                                                <option *ngFor="let c of filterArr(xlabel, 'ylabel')" [value]="c.type">
                                                    {{c.name}}
                                                </option>
                                            </select>
                                            <div class="invalid-tooltip">
                                                Completa este campo.
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-row align-items-start">
                                        <!--? Campañas -->
                                        <div class="form-group col-lg-6" *ngIf="filterForm.get('ylabel').value==1">
                                            <div style="display: flex;justify-content: space-between;">
                                                <label for="">Campañas <span style="color: #FF3333;">*</span></label>
                                                <div class="form-check">
                                                    <label class="form-check-label" for="allCampaign">
                                                        <input class="form-check-input" type="checkbox" id="allCampaign"
                                                            formControlName="allCampaign" (change)="allChange(1,true)">
                                                        <span class="checkmark"></span>
                                                        Seleccione todo
                                                    </label>
                                                </div>
                                            </div>
                                            <select class="form-control" multiple value id="campaign"
                                                [ngClass]="{'is-invalid': isValid('campaign')}">
                                                <option *ngFor="let c of campaignSelect" [value]="c.id">
                                                    {{c.titulo}}
                                                </option>
                                            </select>
                                            <div class="invalid-tooltip">
                                                Completa este campo.
                                            </div>
                                        </div>
                                        <!--? Voceros -->
                                        <div class="form-group col-lg-6" *ngIf="filterForm.get('ylabel').value==2">
                                            <div style="display: flex;justify-content: space-between;">
                                                <label for="">Voceros <span style="color: #FF3333;">*</span></label>
                                                <div class="form-check">
                                                    <label class="form-check-label" for="allSpokesmen">
                                                        <input class="form-check-input" type="checkbox"
                                                            id="allSpokesmen" formControlName="allSpokesmen"
                                                            (change)="allChange(2,true)">
                                                        <span class="checkmark"></span>
                                                        Seleccione todo
                                                    </label>
                                                </div>
                                            </div>
                                            <select class="form-control" id="spokesman" multiple value
                                                [ngClass]="{'is-invalid': isValid('spokesman')}">
                                                <option *ngFor="let s of spokesmen" [value]="s.id">
                                                    {{s.nombres}} {{s.apellidos}}
                                                </option>
                                            </select>
                                            <div class="invalid-tooltip">
                                                Completa este campo.
                                            </div>
                                        </div>
                                        <!--? Plan Medios -->
                                        <div class="form-group col-lg-6" *ngIf="filterForm.get('ylabel').value==3">
                                            <div style="display: flex;justify-content: space-between;">
                                                <label for="">Plan Medios <span style="color: #FF3333;">*</span></label>
                                                <div class="form-check">
                                                    <label class="form-check-label" for="allPlans">
                                                        <input class="form-check-input" type="checkbox" id="allPlans"
                                                            formControlName="allPlans" (change)="allChange(3,true)">
                                                        <span class="checkmark"></span>
                                                        Seleccione todo
                                                    </label>
                                                </div>
                                            </div>
                                            <select class="form-control" id="plan" multiple value
                                                [ngClass]="{'is-invalid': isValid('plans')}">
                                                <option *ngFor="let s of plansMedio" [value]="s.id">
                                                    {{s.nombre}}
                                                </option>
                                            </select>
                                            <div class="invalid-tooltip">
                                                Completa este campo.
                                            </div>
                                        </div>
                                        <!--? Plataformas -->
                                        <div class="form-group col-lg-6"
                                            *ngIf="filterForm.get('ylabel').value==4 && filterForm.get('xlabel').value!=1">
                                            <div style="display: flex;justify-content: space-between;">
                                                <label for="">Plataformas <span style="color: #FF3333;">*</span></label>
                                                <div class="form-check">
                                                    <label class="form-check-label" for="allPlatforms">
                                                        <input class="form-check-input" type="checkbox"
                                                            id="allPlatforms" formControlName="allPlatforms"
                                                            (change)="allChange(4,false)">
                                                        <span class="checkmark"></span>
                                                        Seleccione todo
                                                    </label>
                                                </div>
                                            </div>

                                            <select class="form-control" id="platform" multiple value
                                                [ngClass]="{'is-invalid': isValid('platform')}">
                                                <option *ngFor="let p of platforms" [value]="p.id">{{p.descripcion}}
                                                </option>
                                            </select>
                                            <div class="invalid-tooltip">
                                                Completa este campo.
                                            </div>
                                        </div>
                                        <!--? Tipo Notas -->
                                        <div class="form-group col-lg-6"
                                            *ngIf="filterForm.get('ylabel').value==5 && filterForm.get('xlabel').value!=2">
                                            <div style="display: flex;justify-content: space-between;">
                                                <label for="">Tipo de Notas <span
                                                        style="color: #FF3333;">*</span></label>
                                                <div class="form-check">
                                                    <label class="form-check-label" for="allNotes">
                                                        <input class="form-check-input" type="checkbox" id="allNotes"
                                                            formControlName="allNotes" (change)="allChange(5,false)">
                                                        <span class="checkmark"></span>
                                                        Seleccione todo
                                                    </label>
                                                </div>
                                            </div>

                                            <select class="form-control" id="note" multiple value
                                                [ngClass]="{'is-invalid': isValid('note')}">
                                                <option *ngFor="let n of notes" [value]="n.id">{{n.descripcion}}
                                                </option>
                                            </select>
                                            <div class="invalid-tooltip">
                                                Completa este campo.
                                            </div>
                                        </div>
                                        <!--? Tier -->
                                        <div class="form-group col-lg-6"
                                            *ngIf="filterForm.get('ylabel').value==6 && filterForm.get('xlabel').value!=3">
                                            <div style="display: flex;justify-content: space-between;">
                                                <label for="">Tier <span style="color: #FF3333;">*</span></label>
                                                <div class="form-check">
                                                    <label class="form-check-label" for="allTiers">
                                                        <input class="form-check-input" type="checkbox" id="allTiers"
                                                            formControlName="allTiers" (change)="allChange(6,false)">
                                                        <span class="checkmark"></span>
                                                        Seleccione todo
                                                    </label>
                                                </div>
                                            </div>
                                            <select class="form-control" id="tier" multiple value
                                                [ngClass]="{'is-invalid': isValid('tiers')}">
                                                <option *ngFor="let p of tiers" [value]="p.id">{{p.descripcion}}
                                                </option>
                                            </select>
                                            <div class="invalid-tooltip">
                                                Completa este campo.
                                            </div>
                                        </div>
                                        <!--!-->          <!--!-->
                                        <!--? Plataformas -->
                                        <div class="form-group col-lg-6"
                                            *ngIf="filterForm.get('xlabel').value==1 && filterForm.get('ylabel').value!=4">
                                            <div style="display: flex;justify-content: space-between;">
                                                <label for="">Plataformas <span style="color: #FF3333;">*</span></label>
                                                <div class="form-check">
                                                    <label class="form-check-label" for="allPlatforms">
                                                        <input class="form-check-input" type="checkbox"
                                                            id="allPlatforms" formControlName="allPlatforms"
                                                            (change)="allChange(4,false)">
                                                        <span class="checkmark"></span>
                                                        Seleccione todo
                                                    </label>
                                                </div>
                                            </div>

                                            <select class="form-control" id="platform" multiple value
                                                [ngClass]="{'is-invalid': isValid('platform')}">
                                                <option *ngFor="let p of platforms" [value]="p.id">{{p.descripcion}}
                                                </option>
                                            </select>
                                            <div class="invalid-tooltip">
                                                Completa este campo.
                                            </div>
                                        </div>
                                        <!--? Tipo Notas -->
                                        <div class="form-group col-lg-6"
                                            *ngIf="filterForm.get('xlabel').value==2 && filterForm.get('ylabel').value!=5">
                                            <div style="display: flex;justify-content: space-between;">
                                                <label for="">Tipo de Notas <span
                                                        style="color: #FF3333;">*</span></label>
                                                <div class="form-check">
                                                    <label class="form-check-label" for="allNotes">
                                                        <input class="form-check-input" type="checkbox" id="allNotes"
                                                            formControlName="allNotes" (change)="allChange(5,false)">
                                                        <span class="checkmark"></span>
                                                        Seleccione todo
                                                    </label>
                                                </div>
                                            </div>

                                            <select class="form-control" id="note" multiple value
                                                [ngClass]="{'is-invalid': isValid('note')}">
                                                <option *ngFor="let n of notes" [value]="n.id">{{n.descripcion}}
                                                </option>
                                            </select>
                                            <div class="invalid-tooltip">
                                                Completa este campo.
                                            </div>
                                        </div>
                                        <!--? Tier -->
                                        <div class="form-group col-lg-6"
                                            *ngIf="filterForm.get('xlabel').value==3 && filterForm.get('ylabel').value!=6">
                                            <div style="display: flex;justify-content: space-between;">
                                                <label for="">Tier <span style="color: #FF3333;">*</span></label>
                                                <div class="form-check">
                                                    <label class="form-check-label" for="allTiers">
                                                        <input class="form-check-input" type="checkbox" id="allTiers"
                                                            formControlName="allTiers" (change)="allChange(6,false)">
                                                        <span class="checkmark"></span>
                                                        Seleccione todo
                                                    </label>
                                                </div>
                                            </div>
                                            <select class="form-control" id="tier" multiple value
                                                [ngClass]="{'is-invalid': isValid('tiers')}">
                                                <option *ngFor="let p of tiers" [value]="p.id">{{p.descripcion}}
                                                </option>
                                            </select>
                                            <div class="invalid-tooltip">
                                                Completa este campo.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                </div>

                <div class="timeline-block">
                            <span class="timeline-step">3</span>
                            <div class="timeline-content">
                                <h3>Agregar Detalles</h3>
                                <div class="content-form">
                                    <div class="form-row">
                                        <div class="form-group col-lg-4">
                                            <div class="form-check">
                                                <label class="form-check-label" for="isVal">
                                                    <input class="form-check-input" type="checkbox" id="isVal"
                                                        formControlName="isVal">
                                                    <span class="checkmark"></span>
                                                    Considerar valorización
                                                </label>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg-4">
                                            <div class="form-check">
                                                <label class="form-check-label" for="isDet">
                                                    <input class="form-check-input" type="checkbox" id="isDet"
                                                        formControlName="isDet">
                                                    <span class="checkmark"></span>
                                                    Considerar detalles
                                                </label>
                                            </div>
                                        </div>


                                    </div>
                                    <div  class="d-flex flex-wrap align-items-center" *ngIf="this.filterForm.get('isVal').value">
                                        <div class="custom-control custom-switch mr-3">
                                            <input type="checkbox" class="custom-control-input" id="soles"
                                            formControlName="sol" name="notes" (change)="changeCurrency('sol','dolar')">
                                            <label class="custom-control-label" for="soles">en Soles (S/.)</label>
                                        </div>
                                        <div class="custom-control custom-switch mr-3">
                                            <input type="checkbox" class="custom-control-input" id="dolares"
                                            formControlName="dolar" name="tier" (change)="changeCurrency('dolar','sol')">
                                            <label class="custom-control-label" for="dolares">en Dólares (USD)</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                </div>
            </div>


            <button type="submit" class="btn btn-danger" *ngIf="!btn">Mostrar</button>
            <button type="submit" class="btn btn-danger" *ngIf="btn" disabled>
                <i class="fa fa-spinner fa-pulse"></i>
                Mostrar
            </button>
		</form>
    </div>
</div>
