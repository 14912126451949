import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ImageService {

    constructor(private http: HttpClient) { }

    get(url: string) {
        return this.http.get(url, { responseType: 'blob' }).toPromise();
    }
}
