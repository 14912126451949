import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { CampaignService } from 'src/app/services/campaign.service';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';
// import * as $ from 'jquery';

declare const $: any;
declare const moment: any;

@Component({
  selector: 'app-filter-form',
  templateUrl: './filter-form.component.html',
  styleUrls: ['./filter-form.component.css']
})
export class FilterFormComponent implements OnInit {

  ylabel = [
    { name: 'Campañas',       type: 1, disable: 10, arr: 'campaign'  },
    { name: 'Voceros',        type: 2, disable: 10, arr: 'spokesman' },
    { name: 'Plan de Medios', type: 3, disable: 10, arr: 'plans'     },
    { name: 'Plataformas',    type: 4, disable: 1,  arr: 'platform'  },
    { name: 'Tipo de Notas',  type: 5, disable: 2,  arr: 'note'      },
    { name: 'Tier',           type: 6, disable: 3,  arr: 'tiers'     }
  ]

  xlabel = [
    { name: 'Plataformas',   type: 1, disable: 4, arr: 'platform' },
    { name: 'Tipo de Notas', type: 2, disable: 5, arr: 'note'     },
    { name: 'Tier',          type: 3, disable: 6, arr: 'tiers'    }
  ]

  selects = [
    { id: 1, select: 'campaign',  form: 'campaign',  all: 'allCampaign'  },
    { id: 2, select: 'spokesman', form: 'spokesman', all: 'allSpokesmen' },
    { id: 3, select: 'plan',      form: 'plans',     all: 'allPlans'     },
    { id: 4, select: 'platform',  form: 'platform',  all: 'allPlatforms' },
    { id: 5, select: 'note',      form: 'note',      all: 'allNotes'     },
    { id: 6, select: 'tier',      form: 'tiers',     all: 'allTiers'     }
  ]

  filterForm = this.formBuilder.group({
    customer: [null, Validators.required],
    xlabel:   [null, Validators.required],
    ylabel:   [null, Validators.required],

    campaign:  [[]],
    spokesman: [[]],
    plans:     [[]],
    platform:  [[]],
    note:      [[]],
    tiers:     [[]],

    from: [null, [Validators.required]],
    to:   [null],

    allSpokesmen: [{ value: false, disabled: false }],
    allCampaign:  [{ value: false, disabled: false }],
    allPlans:     [{ value: false, disabled: false }],
    allPlatforms: [{ value: false, disabled: false }],

    allNotes: [false],
    allTiers: [false],
    isVal:    [false],
    isDet:    [false],
    sol:      [true],
    dolar:    [false]
  });

  user: any;

  @Output() submittedForm = new EventEmitter<any>();
  @Output() spinner = new EventEmitter<boolean>();
  @Input()  selectAll: boolean;

  formSubmitAttempt: boolean = false;
  btn: boolean  = false;
  customers: any[] = [];
  platforms: any[] = [];

  notes: any[] = [
    { id: 1, descripcion: "Nota" },
    { id: 2, descripcion: "Entrevista" },
    { id: 3, descripcion: "Reseña" },
    { id: 4, descripcion: "Opinión" }
  ];

  tiers: any[] = [
    { id: 1, descripcion: "Tier 1" },
    { id: 2, descripcion: "Tier 2" },
    { id: 3, descripcion: "Tier 3" }
  ];

  spokesmen: any[] = [];
  plansMedio: any[] = [];
  customersTwo: any[] = [];
  platformsTwo: any[] = [];
  notesTwo: any[] = [];
  spokesmenTwo: any[] = [];
  campaignSelect: any[] = [];
  results: any[] = [];
  data: any;
  customer: string = '';

  subscriptionCustomers: Subscription;
  subscriptionPlatforms: Subscription;
  subscriptionNotes: Subscription;
  subscriptionCampaign: Subscription;
  subscriptionResults: Subscription;

  loadF = false

  constructor(
    private campaignService: CampaignService,
    public userService: UserService,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    this.user = this.userService.user.value;
    if (this.campaignService.filter.idCliente) {
      this.loadF = true
    }

    $(document).ready(() => {
      $('#customer').select2({
        width: '100%',
        placeholder: 'Seleccione',
        allowClear: true
      });

      $('#customer').on('select2:select', (e) => {
        let id = Number(e.params.data.id);
        this.customerChange(id);
      });

      $('#customer').on('select2:clear', (e) => {
        this.customerChange(null);
      });

      $('#ylabel').select2({
        width: '100%',
        placeholder: 'Seleccione',
        allowClear: false
      });

      $('#ylabel').on('select2:select', (e) => {
        this.filterForm.get('ylabel').setValue(Number(e.params.data.id))
        this.activeView(Number(e.params.data.id))
      });

      $('#xlabel').select2({
        width: '100%',
        placeholder: 'Seleccione',
        allowClear: false
      });

      $('#xlabel').on('select2:select', (e) => {
        this.filterForm.get('xlabel').setValue(Number(e.params.data.id))
        this.activeView(Number(e.params.data.id) + 3)
      });
    });

    $('input[name="daterange"]').daterangepicker({
      locale: {
        format: 'DD/MM/YYYY',
        "daysOfWeek": [ "Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa" ],
        "monthNames": [
            "Enero",  "Febrero",  "Marzo",      "Abril",   "Mayo",      "Junio",
            "Julio",  "Agosto",   "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ]
      },
      autoApply: true,
      autoUpdateInput: false,
      linkedCalendars: false,
      showDropdowns: true
    });

    $('input[name="daterange"]').on('apply.daterangepicker', (e, picker) => {
      let startDate = picker.startDate.format('YYYY-MM-DD');
      let endDate = picker.endDate.format('YYYY-MM-DD');
      $('input[name="daterange"]').val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
      this.filterForm.get('from').setValue(startDate);
      this.filterForm.get('to').setValue(endDate);
    });

    if (this.user.cliente_id > 0) {
      this.customerChange(this.user.cliente_id);
    }

    this.subscriptionPlatforms = this.campaignService.getPlatforms().subscribe(response => {
      this.platforms = response;
      this.filterForm.get('allPlatforms').enable();
    });

    this.subscriptionCustomers = this.campaignService.getCustomers().subscribe(response => {
      if (this.user.cliente_id > 0) {
        this.customer = response.find(c => c.id === this.user.cliente_id).nombreComercial;
      }
      this.customers = response;
      if (this.campaignService.filter.idCliente) {
        this.customerChange(this.campaignService.filter.idCliente)
      }
    });
  }

  activeView(id) {
    let selec = this.selects.find(s => s.id == id)
    this.filterForm.get(selec.form).setValue([])
    $(document).ready(() => {
      $(`#${selec.select}`).select2({
        width: '100%',
        placeholder: 'Seleccione',
        allowClear: true
      });

      $(`#${selec.select}`).on('select2:select', (e) => {
        let arr = [...this.filterForm.get(selec.form).value]
        arr.push(Number(e.params.data.id));
        this.filterForm.get(selec.form).setValue(arr)
      });

      $(`#${selec.select}`).on('select2:unselect', (e) => {
        let arr = [...this.filterForm.get(selec.form).value]
        arr = arr.filter(c => c !== Number(e.params.data.id));
        this.filterForm.get(selec.form).setValue(arr)
        if (this.filterForm.get(selec.all).value) {
          this.filterForm.get(selec.all).setValue(false)
        }
      });
    })

  }

  filterArr(arr, dis) {
    return arr.filter(ft => ft.disable != this.filterForm.get(dis).value)
  }

  getFilterData() {
    //this.customerChange(this.campaignService.filter.idCliente)
    if (this.campaignService.filter.allCampaigns) {
      this.filterForm.get('allCampaign').setValue(true);
    }
    if (this.campaignService.filter.allSpokesmen) {
      this.filterForm.get('allSpokesmen').setValue(true);
    }
    if (this.campaignService.filter.campaigns.length) {
      this.filterForm.get('campaign').setValue(this.campaignService.filter.campaigns);
      this.customersTwo = this.campaignService.filter.campaigns
      $('#campaign').val(this.campaignService.filter.campaigns);
      $('#campaign').trigger('change');
    }
    if (this.campaignService.filter.spokesman.length) {
      this.filterForm.get('spokesman').setValue(this.campaignService.filter.spokesman);
      this.spokesmenTwo = this.campaignService.filter.spokesman
      $('#spokesman').val(this.campaignService.filter.spokesman);
      $('#spokesman').trigger('change');
    }

    if (this.campaignService.filter.plataformas) {
      this.filterForm.get('allPlatforms').setValue(true)
      //this.allPlatformsChange()
    }
    if (!this.campaignService.filter.plataformas) {
      this.filterForm.get('allNotes').setValue(true);
      //this.allNotesChange()
    }
    this.filterForm.get('isVal').setValue(this.campaignService.filter.isVal);
    this.filterForm.get('isDet').setValue(this.campaignService.filter.isDet);

    this.filterForm.get('from').setValue(this.campaignService.filter.fechaInicio);
    this.filterForm.get('to').setValue(this.campaignService.filter.fechaFin);
    let start = this.campaignService.filter.fechaInicio.split('-')
    let end = this.campaignService.filter.fechaFin.split('-')
    $('input[name="daterange"]').val(`${start[2]}/${start[1]}/${start[0]} - ${end[2]}/${end[1]}/${end[0]}`);
    this.loadF = false
    this.onSubmit()
  }

  ngOnDestroy() {
    this.subscriptionCustomers.unsubscribe();
    this.subscriptionPlatforms.unsubscribe();

    if (this.subscriptionCampaign) {
      this.subscriptionCampaign.unsubscribe();
    }
    if (this.subscriptionResults) {
      this.subscriptionResults.unsubscribe();
    }
  }

  customerChange(value) {
    this.filterForm.get('customer').setValue(value);
    let all = ['allSpokesmen', 'allCampaign', 'allPlatforms']
    all.forEach(al => {
      if (this.filterForm.get(al).enable) {
        this.filterForm.get(al).disable();
      }
    })

    this.subscriptionCampaign = combineLatest(
      this.campaignService.getSpokesmen(value),
      this.campaignService.getCampaignSelect(value),
      this.campaignService.getPlans(value)
    ).subscribe(([voceros, campanias, plans]) => {
      this.spokesmen = voceros;
      this.campaignSelect = campanias;
      this.plansMedio = plans;
      all.forEach(al => {
        this.filterForm.get(al).enable();
      })
      if (this.campaignService.filter.idCliente) {
        setTimeout(() => {
          this.getFilterData()
        });
      }
    })
  }

  allChange(type, show) {
    const array = [
      { id: 1, select: '#campaign',     form: 'campaign',   all: 'allCampaign',     arr: this.campaignSelect },
      { id: 2, select: '#spokesman',    form: 'spokesman',  all: 'allSpokesmen',    arr: this.spokesmen },
      { id: 3, select: '#plan',         form: 'plans',      all: 'allPlans',        arr: this.plansMedio },
      { id: 4, select: '#platform',     form: 'platform',   all: 'allPlatforms',    arr: this.platforms },
      { id: 5, select: '#note',         form: 'note',       all: 'allNotes',        arr: this.notes },
      { id: 6, select: '#tier',         form: 'tiers',      all: 'allTiers',        arr: this.tiers }
    ]
    let selec = array.find(s => s.id == type)
    if (this.filterForm.get(selec.all).value) {
      let filter = selec.arr.map(p => p.id);
      this.filterForm.get(selec.form).setValue(filter);
      if (show) {
        $(selec.select).val([]);
      } else {
        $(selec.select).val(filter);
      }
      $(selec.select).trigger('change.select2');

    } else {
      this.filterForm.get(selec.form).setValue([]);
      $(selec.select).val([]);
      $(selec.select).trigger('change.select2');
    }
  }

  changeCurrency(form1, form2) {
    if (this.filterForm.get(form1).value) {
      this.filterForm.get(form2).setValue(false)
    }else{
      this.filterForm.get(form2).setValue(true)
    }
  }

  onSubmit() {
    this.formSubmitAttempt = true;
    if (this.filterForm.invalid) {
      return;
    }
    let reportInfo = this.filterForm.value

    const x = reportInfo.xlabel
    const y = reportInfo.ylabel

    const arrX = this.xlabel.find(lb => lb.type == x)
    const arrY = this.ylabel.find(lb => lb.type == y)

    if (reportInfo[arrY.arr].length == 0 || reportInfo[arrX.arr].length == 0) {
      return;
    }

    this.btn = true;
    let saveData = {
      idCliente: reportInfo.customer,
      campaigns:    y == 1 ? reportInfo.campaign : [],
      voceros:      y == 2 ? reportInfo.spokesman : [],
      planmedios:   y == 3 ? reportInfo.plans : [],
      plataformas:  y == 4 || x == 1 ? reportInfo.platform : [],
      tipoNotas:    y == 5 || x == 2 ? reportInfo.note : [],
      tipoTiers:    y == 6 || x == 3 ? [0].concat(reportInfo.tiers) : [],
      fechaInicio: this.filterForm.get('from').value,
      fechaFin: this.filterForm.get('to').value,
      isDet: reportInfo.isDet ? 1 : 0,
      isVal: reportInfo.isVal ? 1 : 0,
      currency: reportInfo.sol ? 'sol' : 'dolar'
    }
    this.spinner.emit(true);
    this.subscriptionResults = this.campaignService.getAll(saveData).subscribe((response: any) => {
      this.spinner.emit(false);
      this.btn = false;
      if (this.campaignService.filter.idCliente) {
        this.campaignService.filter = {
          idCliente: null,
          allCampaigns: false,
          allSpokesmen: false,
          plataformas: false,
          fechaInicio: '',
          fechaFin: '',
          isDet: false,
          isVal: false,
          campaigns: [],
          spokesman: []
        }
      }
      response['customer'] = this.customers.find(cl => cl.id == reportInfo['customer'])
      response.DPRPs = response.DPRPs.map(el => {
        el.note = this.notes.find(nt => nt.id === el.tipoNota)?.descripcion
        el.tierName = this.tiers.find(nt => nt.id === el.tipoTier)?.descripcion
        el.valorizar = Number(el.valorizado.toFixed(2))
        return el
      })
      response.params.xlabel = Number(x)
      response.params.ylabel = Number(y)
      response.params.platforms = this.platforms.filter(pl => reportInfo['platform'].includes(pl.id))
      response.valorizadoTotal = Number(response.valorizadoTotal.toFixed(2))

      this.submittedForm.emit(response);
    }, (error) => {
      this.btn = false;
    });
  }

  isFieldValid(field: string) {
    return this.filterForm.get(field).invalid && this.formSubmitAttempt;
  }

  isValid(field: string) {
    let value = this.filterForm.get(field).value
    return value.length == 0 && this.formSubmitAttempt;
  }
}
