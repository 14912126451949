import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { UserService } from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  url = environment.url;

  constructor(
    private http: HttpClient,
    private userService: UserService
    ) { }

  pdf(data) {
    Swal.fire({
      text: 'Por favor espere...',
      imageUrl: 'assets/img/info.png',
      customClass: {
        title: 'my-swal-title-class',
        content: 'my-swal-content-class',
      },
      allowOutsideClick: false
    });

    Swal.showLoading();

    this.http.post(`/resultadoPlataformas/data/generateReporte`, data).subscribe((response: any) => {
      this.download(`${this.url}/api/downloadFile/${response.filename}`, response.filename);
      Swal.close();
    }, (error) => Swal.close());
  }

  download(dataurl, filename) {
    let a = document.createElement("a");
    a.href = dataurl;
    a.setAttribute("download", filename);
    a.click();
  }
}
