<!-- Button trigger modal -->
<button type="button" class="btn btn-sm btn-danger" data-toggle="modal" data-target="#exampleModal">
    Vista previa
</button>

<!-- Modal -->
<div class="modal fade" id="exampleModal" data-backdrop="static" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <div class="row flex-grow-1">
                    <div class="col-md-6 text-center">
                        <button class="btn btn-link" (click)="pdf()">
                            <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Descargar en Pdf
                        </button>
                    </div>
                    <div class=" col-md-6 text-center">
                        <button class="btn btn-link" (click)="excel()">
                            <i class="fa fa-file-excel-o" aria-hidden="true"></i> Descargar en Excel
                        </button>
                    </div>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()"
                    style="padding: 0;">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row justify-content-between align-items-center" style="margin-bottom: 25px;">
                    <div class="col-auto">
                        <h5 class="modal-title" id="exampleModalLabel">REPORTE DETALLADO POR {{title}}</h5>
                    </div>
                    <div class="col-auto">
                        <img *ngIf="!id" src="assets/img/Logotipo-07.png" alt="" width="90">
                        <img *ngIf="id" [src]="id | image:'logo' | async" alt="logo" alt="" width="90">
                    </div>
                </div>
                <div class="row">
                    <div class="col-auto">
                        <img [src]="data.customer | image:'customer' | async" style="max-height: 36px; max-width: 120;">
                    </div>
                </div>
                <div class="row">
                    <div class="col-auto">
                        <p><strong>Duración:</strong> del {{data.params.fechaInicio | date:'dd-MM-yyy'}} al
                            {{data.params.fechaFin | date:'dd-MM-yyy'}} <br> <strong>Publicaciones por <span
                                    class="text-lowercase">{{title}}</span>:</strong> </p>
                    </div>
                </div>

                <div class="table-responsive tablewithoutScrollStickyHeader"
                    *ngIf="data.params.isDet || data.params.isVal">
                    <table style="width: 100%;">
                        <thead>
                            <tr class="text-white">
                                <th *ngIf="data.params.isDet">
                                    ALCANCE TOTAL
                                </th>
                                <th *ngIf="data.params.isVal">
                                    VALORIZACIÓN TOTAL
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center" *ngIf="data.params.isDet">
                                    {{alcanceTotal | dolar}}
                                </td>
                                <td class="text-center" *ngIf="data.params.isVal">
                                    {{valorTotal | dolar:data.params.currency}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="row" style="margin-bottom: 40px;">
                    <div class="col-md-12">
                        <div class="table-responsive"
                            [ngClass]="{'tablewithoutScrollStickyHeader': type==2, 'tableScrollStickyHeader': type==1}"
                            infiniteScroll #scrollOne [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
                            [scrollWindow]="false" (scrolled)="onModalScrollDownOne()">
                            <table style="width: 100%;">
                                <thead>
                                    <tr class="text-white">
                                        <th *ngFor="let t of dataTitleOne">{{t}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of dataSliceOne; index as i">
                                        <td [ngClass]="{'tr-odd': i%2!==0}" style="max-width: 290px;">
                                            {{item.name}}
                                        </td>
                                        <td [ngClass]="{'tr-odd': i%2!==0}" class="text-center"
                                            *ngFor="let q of item.quantity">
                                            {{q || '-'}}
                                        </td>
                                        <td [ngClass]="{'tr-odd': i%2!==0}" class="text-center">
                                            <strong>{{item.total}}</strong>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>
                                            TOTAL
                                        </th>
                                        <th class="text-center" *ngFor="let item of dataTableOneTotals">
                                            {{item}}
                                        </th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="table-responsive tableScrollStickyHeaderTwo" infiniteScroll #scrollThree
                            [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false"
                            (scrolled)="onModalScrollDownTwo()">
                            <table style="width: 100%;">
                                <tr *ngFor="let item of dataSliceTwo">
                                    <td>
                                        <table style="width: 100%;">
                                            <thead>
                                                <tr *ngIf="item.shwoFirst">
                                                    <th colspan="2" style="z-index:2">
                                                        {{item[name]}}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <app-result-div [item]="item" [xlabel]="type==1?data.xlabel:0"
                                                            [ylabel]="data.ylabel" [val]="data.params.isVal"
                                                            [det]="data.params.isDet" [currency]="data.params.currency">
                                                        </app-result-div>
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>