<div class=".was-validated formR">
    <div *ngIf="view==1">
        <h3>Seleccione un cliente:</h3>
        <div class="div-content">
            <div class="form-group">
                <select class="form-control" id="customer" [formControl]="customerId">
                    <option></option>
                    <option *ngFor="let c of customers" [value]="c.id">{{c.nombreComercial}}
                    </option>
                </select>
                <!--div class="invalid-tooltip">
                    Completa este campo.
                </div-->
            </div>
        </div>
    </div>
    <div *ngIf="view==2">
        <h3>Seleccione un tipo de reporte:</h3>
        <div class="div-content">
            <div class="form-check my-2" *ngFor="let c of reportes">
                <label class="form-check-label" [for]="c.id">
                    <input class="form-check-input" type="checkbox" [id]="c.id" [(ngModel)]="c.check"
                        (ngModelChange)="reportChange(c)">
                    <span class="checkmark"></span>
                    {{c.name}}
                </label>
            </div>
        </div>
    </div>
    <div *ngIf="view==3">
        <h3>{{reportSelect}}
            <span *ngIf="allPlatforms">por plataformas</span>
            <span *ngIf="allNotes">por tipo de notas</span>
            <span *ngIf="allTiers">por tier</span>
        </h3>
        <div class="div-content">
            <div *ngIf="reportId!=4">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch1" [(ngModel)]="allPlatforms"
                        (ngModelChange)="allPlatformsChange()">
                    <label class="custom-control-label" for="customSwitch1">Por Plataformas</label>
                </div>
                <div class="d-flex flex-wrap mt-1">
                    <div class="form-check col-lg-3 col-md-4 col-6" *ngFor="let p of platforms">
                        <label class="form-check-label" [for]="p.name">
                            <input class="form-check-input" type="checkbox" [(ngModel)]="p.check" [id]="p.name"
                                [disabled]="p.disabled">
                            <span class="checkmark"></span>
                            {{p.descripcion}}
                        </label>
                    </div>
                </div>
            </div>

            <div class="mt-4" *ngIf="reportId!=5">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch2" [(ngModel)]="allNotes"
                        (ngModelChange)="allNotesChange()">
                    <label class="custom-control-label" for="customSwitch2">Por Tipo de Notas</label>
                </div>
                <div class="d-flex flex-wrap mt-1">
                    <div class="form-check col-lg-3 col-md-4 col-6" *ngFor="let n of notes">
                        <label class="form-check-label" [for]="n.name">
                            <input class="form-check-input" type="checkbox" [(ngModel)]="n.check" [id]="n.name"
                                [disabled]="n.disabled">
                            <span class="checkmark"></span>
                            {{n.descripcion}}
                        </label>
                    </div>
                </div>
            </div>

            <div class="mt-4" *ngIf="reportId!=6">
                <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch3" [(ngModel)]="allTiers"
                        (ngModelChange)="allTiersChange()">
                    <label class="custom-control-label" for="customSwitch3">Por Tier</label>
                </div>
                <div class="d-flex flex-wrap mt-1">
                    <div class="form-check col-lg-3 col-4" *ngFor="let n of tiers">
                        <label class="form-check-label" [for]="n.name">
                            <input class="form-check-input" type="checkbox" [(ngModel)]="n.check" [id]="n.name"
                                [disabled]="n.disabled">
                            <span class="checkmark"></span>
                            {{n.descripcion}}
                        </label>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div *ngIf="view==4">
        <h3>Seleccione un rango de fecha:</h3>
        <div class="div-content">
            <div class="form-group">
                <input type="text" class="form-control" name="daterange" autocomplete="off" [formControl]="dateRange" />
                <div class="invalid-tooltip">
                    Completa este campo.
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="view==5">
        <h3>Seleccione una opción: <span style="font-weight:400;font-size: 11px;">(opcional)</span></h3>
        <div class="div-content">
            <div class="form-check my-3">
                <label class="form-check-label" for="val">
                    <input class="form-check-input" type="checkbox" id="val" [(ngModel)]="isVal">
                    <span class="checkmark"></span>
                    Considerar valorización
                </label>
            </div>
            <div class="form-check my-3">
                <label class="form-check-label" for="det">
                    <input class="form-check-input" type="checkbox" id="det" [(ngModel)]="isDet">
                    <span class="checkmark"></span>
                    Considerar detalles
                </label>
            </div>
        </div>
    </div>

    <div *ngIf="view==6">
        <h3>Estamos generando tu reporte</h3>
        <div class="div-content d-flex flex-wrap align-items-center justify-content-center">
            <i class="fa fa-spinner fa-pulse" aria-hidden="true" style="font-size: 60px;
            color: #f33;"></i>
        </div>
    </div>
    <div class="d-flex flex-wrap">
        <button type="button" class="btn min-btn" (click)="backView()" *ngIf="view>1&&view!=6">
            <i class="fa fa-chevron-left" aria-hidden="true" style="margin-right:5px;"></i> Anterior
        </button>
        <span style="flex: 1 1;"></span>
        <button type="button" *ngIf="view<5 && disableNextButon()" (click)="changeView()" class="btn min-btn">
            Siguiente <i class="fa fa-chevron-right" style="margin-left:5px;" aria-hidden="true"></i>
        </button>
        <button type="button" *ngIf="view<5 && !disableNextButon()" class="btn min-btn" disabled>
            Siguiente <i class="fa fa-chevron-right" style="margin-left:5px;" aria-hidden="true"></i>
        </button>
        <button type="button" *ngIf="view==5" (click)="onSubmit()" class="btn btn-danger">Generar</button>
    </div>
</div>