import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-result-div',
  templateUrl: './result-div.component.html',
  styleUrls: ['./result-div.component.css']
})
export class ResultDivComponent implements OnInit {

  @Input() item: any;
  @Input() xlabel: number;
  @Input() ylabel: number;
  @Input() val: boolean;
  @Input() det: boolean;
  @Input() currency: string;
  
  // someDefaultImage: string = 'assets/img/noimage.png';
  
  constructor() { }

  ngOnInit(): void {
  }

  goTo(url): void {
    window.open(url, "_blank");
  }

}
