import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  private param = new BehaviorSubject<any[]>([]);
  param$ = this.param.asObservable();
  url = environment.url;
  data: any;
  filter: {
    idCliente: number;
    allCampaigns: boolean;
    allSpokesmen: boolean;
    plataformas: boolean;
    fechaInicio: string;
    fechaFin: string;
    isDet: boolean;
    isVal: boolean;
    campaigns: any;
    spokesman: any;
  } = {
      idCliente: null,
      allCampaigns: false,
      allSpokesmen: false,
      plataformas: false,
      fechaInicio: '',
      fechaFin: '',
      isDet: false,
      isVal: false,
      campaigns: [],
      spokesman: []
    };

  constructor(private http: HttpClient) {
    this.loadData();
  }

  getParam(param: any) {
    this.param.next(param);
  }

  getAll(data) {
    return this.http.post(`/resultadoPlataformas/data/getResultadosForReporte`, data)
  }

  getOne(id, tenant?) {
    return this.http.get(`${this.url}/api/detallePlanResultadoPlataformas/data/getByIdEncriptado/${id}`);
    /*if(tenant){
      return this.http.get(`${this.url}/${tenant}/detallePlanResultadoPlataformas/data/getByIdEncriptado/${id}`);
    }else{
      return this.http.get(`/detallePlanResultadoPlataformas/data/getByIdEncriptado/${id}`);
    }*/
    
  }

  getImageResults(id) {
    return this.http.get(`/resultadoPlataformas/data/images/${id}`);
  }

  getCustomers() {
    return this.http.get(`/clientes`).pipe(
      map((response: any) => {
        return response.clientes;
      })
    );
  }

  getAllCampaigns() {
    return this.http.get(`/campaigns`).pipe(
      map((response: any) => {
        return response.campaigns;
      })
    );
  }

  getAllSpokesmen() {
    return this.http.get(`/personas/data/getVoceros`).pipe(
      map((response: any) => {
        return response.voceros;
      })
    );
  }

  getGroups() {
    return this.http.get(`/campaignGroups`).pipe(
      map((response: any) => {
        return response.campaignGroups;
      })
    );;
  }

  getPlatforms() {
    return this.http.get(`/plataformas`).pipe(
      map((response: any) => {
        return response.plataformas;
      })
    );;
  }

  getSpokesmen(id: number) {
    return this.http.get(`/clienteVoceros/data/getListByCliente/${id}`).pipe(
      map((response: any) => {
        return response.clienteVoceros.map(vc => vc.vocero);
      })
    );;
  }

  getCampaignSelect(id: number) {
    return this.http.get(`/campaigns/data/getListByCliente/${id}`).pipe(
      map((response: any) => {
        return response.campaigns;
      })
    );
  }

  getPlans(id) {
    return this.http.get(`/planMedios/data/getListByCliente/${id}`).pipe(
      map((response: any) => {
        return response.planMedios;
      })
    );
  }

  loadData() {
    if (localStorage.getItem('data')) {
      this.data = JSON.parse(localStorage.getItem('data'));
    }
  }

  cleanData() {
    this.data = undefined;
  }

  getAudio(audio) {
    const body = {
      "audio": {
        "content": audio
      },
      "config": {
        "enableAutomaticPunctuation": true,
        "encoding": "LINEAR16",
        "languageCode": "es-PE",
        "model": "default"
      }
    }
    return this.http.post('https://speech.googleapis.com/v1p1beta1/speech:recognize?key=AIzaSyBKgWFLtt9w6BQfs2l5FSJ9pp_XWWDsHcw', body);
  }

  getImage(data) {
    return this.http.get(`/resultadoPlataformas/data/displayImageResultado/${data}`, { responseType: 'blob' }).pipe(
      /*map(img => {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.onloadend = () => resolve(reader.result as string);
          reader.readAsDataURL(img);
        });
      })*/
    )
  }
}
