<button type="button" class="btn btn-link" data-toggle="modal" data-target="#exampleModal"
    style="padding: 0; margin-bottom: 10px;">
    ¿Olvidaste tu contraseña?
</button>

<!-- Modal -->
<div class="modal fade" id="exampleModal" data-backdrop="static" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Restablecer contraseña</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closebutton
                    [disabled]="(!code && btn) || (code && btnTwo)" (click)="clickCancel()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="padding: 30px 16px;">
                <form ngNativeValidate *ngIf="!code" #passwordForm="ngForm" id="passwordFormId"
                    (ngSubmit)="recoverPasswordOne(passwordForm)">
                    <div class="alert alert-danger alert-dismissible fade show alertPasswordForm" role="alert"
                        style="display: none;">
                        <img src="assets/img/icon-alert-danger.svg" width="14" alt="" style="margin-right: 9px;">El
                        correo no existe
                        <button type="button" class="close" aria-label="Close" (click)="closeAlert(1)">

                            <img src="assets/img/close.svg" width="10" alt="" style="margin-right: 9px;">
                        </button>
                    </div>
                    <div class="form-group" style="margin: 0;">
                        <input type="email" class="form-control" placeholder="Email" [(ngModel)]="email" name="email"
                            required>
                    </div>
                </form>
                <form ngNativeValidate *ngIf="code" #passwordFormTwo="ngForm" id="passwordFormIdTwo"
                    (ngSubmit)="recoverPasswordTwo(passwordFormTwo)" style="text-align: left;">

                    <div class="alert alert-success alert-dismissible fade show alertpasswordFormTwo-success"
                        role="alert">

                        <img src="assets/img/correct.svg" width="14" alt="" style="margin-right: 9px;">
                        Se ha enviado un código al correo {{email}}
                        <button type="button" class="close" aria-label="Close" (click)="closeAlert(2)">

                            <img src="assets/img/close-success.svg" width="10" alt="" style="margin-right: 9px;">
                        </button>
                    </div>
                    <div class="alert alert-danger alert-dismissible fade show alertpasswordFormTwo-danger" role="alert"
                        style="display: none;">

                        <img src="assets/img/icon-alert-danger.svg" width="14" alt="" style="margin-right: 9px;">
                        Las contraseñas no son iguales
                        <button type="button" class="close" aria-label="Close" (click)="closeAlert(3)">

                            <img src="assets/img/close.svg" width="10" alt="" style="margin-right: 9px;">
                        </button>
                    </div>
                    <div class="alert alert-danger alert-dismissible fade show alertpasswordFormTwo-dangerTwo"
                        role="alert" style="display: none;">

                        <img src="assets/img/icon-alert-danger.svg" width="14" alt="" style="margin-right: 9px;">
                        El código no es valido
                        <button type="button" class="close" aria-label="Close" (click)="closeAlert(4)">

                            <img src="assets/img/close.svg" width="10" alt="" style="margin-right: 9px;">
                        </button>
                    </div>

                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Código"
                            [(ngModel)]="passwordNew.codeResetPassword" name="code" required>
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control" placeholder="Contraseña"
                            [(ngModel)]="passwordNew.newPassword" name="password" required>
                    </div>
                    <div class="form-group" style="margin: 0;">
                        <input type="password" class="form-control" placeholder="Repetir contraseña"
                            [(ngModel)]="passwordNew.passwordTwo" name="passwordTwo" required>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                    [disabled]="(!code && btn) || (code && btnTwo)" (click)="cancel()">Cancelar</button>
                <button *ngIf="!code && !btn" type="submit" class="btn btn-primary" form="passwordFormId">
                    Enviar
                </button>
                <button *ngIf="!code && btn" type="submit" class="btn btn-primary" form="passwordFormId" disabled>
                    <i class="fa fa-spinner fa-pulse"></i>
                    Enviar
                </button>
                <button *ngIf="code && !btnTwo" type="submit" class="btn btn-primary" form="passwordFormIdTwo">
                    Enviar
                </button>
                <button *ngIf="code && btnTwo" type="submit" class="btn btn-primary" form="passwordFormIdTwo" disabled>
                    <i class="fa fa-spinner fa-pulse"></i>
                    Enviar
                </button>
            </div>
        </div>
    </div>
</div>